import React from 'react';
import {
  Avatar,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import PetsRoundedIcon from '@material-ui/icons/PetsRounded';

import { useSettingContext } from '../SettingContext';
import { inAppNotfication } from '../components/InAppPushNotifition';
import routeData from '../routeData';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(3),
  },
  settingListItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  notification: {
    color: theme.palette.info.main,
  },
}));

const PageButton = ({ color, icon, path, title }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <ListItem button divider className={classes.settingListItem} onClick={() => history.push(path)}>
      <ListItemAvatar>
        <Avatar className={classes.avatar} style={{ background: color }}>
          {icon('default')}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} />
    </ListItem>
  );
};

const ActionButton = ({ primary, secondary = '', avatar, onClick, avatarStyle, classes, ...props }) => {
  return (
    <ListItem button divider className={classes.settingListItem} onClick={onClick} {...props}>
      <ListItemAvatar>
        <Avatar className={classes.avatar} style={avatarStyle}>
          {avatar ?? primary}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );
};

const Space = ({ classes }) => <ListItem className={classes.settingListItem}></ListItem>;

const SubTitle = ({ title, classes }) => {
  return (
    <ListItem divider className={classes.settingListItem}>
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default function MorePage() {
  const classes = useStyles();
  const settingContext = useSettingContext();

  const noticePageData = routeData.find(_ => _.path === '/notice');
  const worshipPageData = routeData.find(_ => _.path === '/worship');

  return (
    <List className={classes.root}>
      {routeData
        .filter(_ => !!_.isShowInMore)
        .map(({ color, icon, path, title }) => {
          const props = { color, icon, path, title };
          return <PageButton {...props} key={path} />;
        })}
      <Space classes={classes} />
      <SubTitle title="更多設定" classes={classes} />
      <ListItem
        button
        divider
        className={classes.settingListItem}
        onClick={() => {
          if (settingContext.fcmToken) copy(settingContext.fcmToken);
        }}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatar} style={{ background: noticePageData.color }}>
            {noticePageData.icon()}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="開啟通知提示" secondary="iOS 暫不支援PWA推送通知" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={() => settingContext.setShowNotification(!settingContext.showNotification)}
            checked={settingContext.showNotification}
            inputProps={{ 'aria-label': '通知提示' }}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ActionButton
        primary="新增到手機主畫面"
        avatar={<GetAppRoundedIcon />}
        avatarStyle={{ background: worshipPageData.color }}
        onClick={() => settingContext.installPromptEvent.prompt()}
        disabled={settingContext.installPromptEvent === undefined}
        classes={classes}
      />
      <Space classes={classes} />
      <SubTitle title="Test" classes={classes} />
      <ActionButton
        primary="Test notification (normal)"
        avatar={<PetsRoundedIcon />}
        onClick={() => inAppNotfication({ title: 'Test notification', body: 'message', autoClose: 'true' })}
        classes={classes}
      />
      <ActionButton
        primary="Test notification (stay)"
        avatar={<PetsRoundedIcon />}
        onClick={() => inAppNotfication({ title: 'Test notification', body: 'message' })}
        classes={classes}
      />
      <ActionButton
        primary="Test notification (long title)"
        avatar={<PetsRoundedIcon />}
        onClick={() =>
          inAppNotfication({
            title: 'Test notification which is very longggggggggggggggggggggggggg',
            body: 'message',
            autoClose: 'true',
          })
        }
        classes={classes}
      />
      <ActionButton
        primary="Test notification (long message)"
        avatar={<PetsRoundedIcon />}
        onClick={() =>
          inAppNotfication({
            title: 'Test notification',
            body: 'very long messageeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
            autoClose: 'true',
          })
        }
        classes={classes}
      />
    </List>
  );
}
