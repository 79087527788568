import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { AppBar, Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { use100vh } from 'react-div-100vh';

import MessageDialog from './MessageDialog';
import BottomNavBar from './BottomNavBar';
import routeData from '../routeData';
import useFirebaseMessaging from '../hooks/useFirebaseCloudMessaging';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    paddingLeft: '0px',
    paddingRight: '0px',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    flexGrow: 1,
    margin: '8px 10px',
  },
}));

const ContentBox = styled(Box)`
  margin-bottom: 3.5rem;
  min-height: ${p => `calc(${p.realheight}px - 3.5rem - 3rem)`};
  height: ${p => `calc(${p.realheight}px - 3.5rem - 3rem)`};
`;

export default function PageContainer({ children }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const realheight = use100vh();

  useFirebaseMessaging();

  useEffect(() => {
    const unblock = history.block(tx => {
      // block if user attempt to go to same page, with no dialog opened now, and not attempt to open a dialog
      // allow user to to open dialog on top of other dialog
      if (tx.pathname === location.pathname && !location.state?.dialogMessage && !tx.state?.dialogMessage) {
        return false;
      }
      unblock();
      return true;
    });

    return () => {
      unblock();
    };
  });

  return (
    <>
      <AppBar position="sticky">
        <Typography variant="h6" className={classes.title}>
          <Switch>
            {routeData.map(({ title, path }) => {
              return (
                <Route exact path={path} key={path}>
                  {title}
                </Route>
              );
            })}
          </Switch>
        </Typography>
      </AppBar>
      <ContentBox realheight={realheight} display="flex" flexDirection="column" overflow="auto">
        <Container className={classes.root}>{children}</Container>
      </ContentBox>
      <BottomNavBar />
      <ToastContainer style={{ top: '0px' }} />
      <MessageDialog />
    </>
  );
}
