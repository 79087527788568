import { useEffect, useState, useRef } from 'react';
import firebase from './firebase';

import { useSettingContext } from '../SettingContext';
import { subscribeToNotice } from './useFirebaseFunctions';
import { InAppPushNotificationHandler } from '../components/InAppPushNotifition';

export default function useFirebaseCloudMessaging() {
  const settingContext = useSettingContext();
  const [currentToken] = useState();
  const currentTokenRef = useRef(currentToken);
  const [isRegisting, setIsRegistering] = useState(false);
  const isRegisteringRef = useRef(isRegisting);

  useEffect(() => {
    try {
      if (
        !isRegisteringRef.current &&
        settingContext.showNotification &&
        (!currentTokenRef.current || currentTokenRef.current !== settingContext.fcmToken)
      ) {
        setIsRegistering(true);
        const messaging = firebase.messaging();
        messaging
          .getToken()
          .then(async token => {
            currentTokenRef.current = token;
            AddEventListener('message', InAppPushNotificationHandler);
            if (settingContext.fcmToken !== token) {
              console.log('[FCM]', 'New token assigned.', token);
              // with callable, fcm token is automatically included in requests
              subscribeToNotice({
                onSuccess: () => {
                  settingContext.setFcmToken(token);
                  settingContext.setShowNotification(true);
                  setIsRegistering(false);
                },
              });
            } else {
              console.log('[FCM]', 'Retrieve token.', token);
              setIsRegistering(false);
            }
          })
          .catch(err => {
            settingContext.setFcmToken(null);
            settingContext.setShowNotification(false);
            setIsRegistering(false);
          });
      } else if (!settingContext.showNotification && currentTokenRef.current) {
        console.log('[FCM]', 'Delete token.', currentTokenRef.current);
        try {
          firebase.messaging().deleteToken();
        } catch (err) {
          console.log('[FCM]', 'Error on delete token. ' + err);
        }
        currentTokenRef.current = undefined;
      }
    } catch (err) {
      // https://firebase.google.com/docs/cloud-messaging/js/client
      // The FCM SDK is supported only in pages served over HTTPS.
      // This is due to its use of service workers, which are available only on HTTPS sites.
      console.log('Firebase failed to set up', err);
      setIsRegistering(false);
    }
  }, [settingContext, currentTokenRef, settingContext.fcmToken, settingContext.showNotification]);

  const AddEventListener = (eventName, eventHandler) => {
    navigator.serviceWorker.addEventListener(eventName, eventHandler);
  };

  return { AddEventListener };
}
