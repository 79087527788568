import React from 'react';
import { BottomNavigation, BottomNavigationAction, Box } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import routeData from '../routeData';

const buttonCount = 5;

const useStyles = makeStyles(theme => ({
  maxWidth: {
    width: '100%',
  },
  stickToBottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    justifyContent: 'space-around',
  },
  bottomNavAction: {
    minWidth: '60px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  bottomLine: {
    flex: 1,
    position: 'fixed',
    bottom: 53,
    height: 0,
    width: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.short,
    }),
  },
  bottomLineSelect: {
    height: 3,
    width: 100 / buttonCount + '%',
  },
}));

const PageButton = ({ value, label, color, icon, ...props }) => {
  const classes = useStyles();
  return (
    <BottomNavigationAction
      value={value}
      component={Link}
      to={value}
      label={label}
      icon={icon()}
      className={classes.bottomNavAction}
      style={{ color: props.selected ? color : 'grey' }}
      {...props}
    />
  );
};

const BottomLine = ({ value, index, color }) => {
  const classes = useStyles();
  const location = useLocation();
  const isSamePage = location.pathname === value;
  return (
    <Box
      className={clsx(classes.bottomLine, {
        [classes.bottomLineSelect]: isSamePage,
      })}
      style={{ backgroundColor: color }}
      left={(100 / buttonCount) * index + '%'}
    />
  );
};

export default function BottomNavBar() {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <BottomNavigation value={location.pathname} showLabels className={clsx(classes.stickToBottom, classes.maxWidth)}>
        {routeData
          .filter(_ => _.isInButtomTab)
          .map(({ path, title, color, icon }) => {
            return <PageButton key={path} value={path} label={title} color={color} icon={icon} />;
          })}
      </BottomNavigation>
      {routeData
        .filter(_ => _.isInButtomTab)
        .map(({ path, title, color }, idx) => {
          return <BottomLine key={path} value={path} index={idx} color={color} />;
        })}
    </>
  );
}
