import React from 'react';

import { useFirestoreContext } from '../FirestoreContext';
import MessageList from '../components/MessageList';

export default function NoticePage() {
  const { notices } = useFirestoreContext();

  return <MessageList messages={notices} />;
}
