import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import { usePdf } from '@mikecousins/react-pdf';

export default function PDFPage({
  file,
  page,
  scale,
  minPdfScale = 3,
  // when set to true, scale it carefully, as large canvas result in app down
  allowResizePdfScale = false,
  // only used when allowResizePdfScale is set to true
  tolerancePdfScaleDiff = 1,
  containerRef = null,
  onPageLoadSuccess = null,
  onPageLoadFail = null,
}) {
  // the scale of the loaded pdf
  const [pdfScale, setPdfScale] = useState(minPdfScale);
  const [{ defaultPageWidth, defaultPageHeight, pageRatio }, setPageSize] = useState({
    defaultPageWidth: 0,
    defaultPageHeight: 0,
    pageRatio: 1,
  });
  const [numPages, setNumPages] = useState(0);
  const [pageLoadedTrigger, setPageLoadedTrigger] = useState(true);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current && defaultPageWidth && defaultPageHeight) {
      const containerWidth = containerRef.current.offsetWidth;
      let expectedWidth = containerWidth * scale;
      let expectedHeight = (containerWidth / pageRatio) * scale;

      if (allowResizePdfScale) {
        const currPdfScale = Math.max(
          minPdfScale,
          Math.max(Math.ceil(expectedWidth / defaultPageWidth), Math.ceil(expectedHeight / defaultPageHeight))
        );

        const updatePdfScale = Math.abs(currPdfScale - pdfScale) > tolerancePdfScaleDiff;
        if (updatePdfScale) {
          setPdfScale(currPdfScale);
        }
      }
      // if not allowed to resize, keep correct ratio
      else if (expectedWidth > canvasRef.current.width || expectedHeight > canvasRef.current.height) {
        expectedWidth = canvasRef.current.width;
        expectedHeight = canvasRef.current.height;
      }
      canvasRef.current.style.width = expectedWidth + 'px';
      canvasRef.current.style.height = expectedHeight + 'px';
    }
  }, [
    defaultPageWidth,
    defaultPageHeight,
    pageRatio,
    containerRef,
    pdfScale,
    scale,
    pageLoadedTrigger,
    page,
    allowResizePdfScale,
    minPdfScale,
    tolerancePdfScaleDiff,
  ]);

  const handleOnDocumentLoadSuccess = ({ _pdfInfo }) => {
    setNumPages(_pdfInfo.numPages);
  };

  const handleOnPageLoadSuccess = ({ _pageInfo }) => {
    console.log('Load ' + file + ' page#' + page + ' [success]');
    setPageSize({
      defaultPageWidth: _pageInfo.view[2],
      defaultPageHeight: _pageInfo.view[3],
      pageRatio: _pageInfo.view[2] / _pageInfo.view[3],
    });
    if (onPageLoadSuccess) onPageLoadSuccess({ hasNextPage: page + 1 <= numPages, numPages: numPages });
    setPageLoadedTrigger(!pageLoadedTrigger);
  };

  const handleOnPageLoadFail = () => {
    console.log('Load ' + file + ' page#' + page + ' [fail]');
    if (onPageLoadFail) onPageLoadFail();
  };

  const { pdfDocument } = usePdf({
    file: file,
    page: page,
    canvasRef: canvasRef,
    scale: pdfScale,
    onDocumentLoadSuccess: handleOnDocumentLoadSuccess,
    onPageLoadSuccess: handleOnPageLoadSuccess,
    onPageLoadFail: handleOnPageLoadFail,
  });

  return (
    <>
      {!pdfDocument && <CircularProgress />}
      <canvas ref={canvasRef} />
    </>
  );
}
