import React, { useContext } from 'react';
import useFirestore from './hooks/useFirestore';

export const FirestoreContext = React.createContext();

export const useFirestoreContext = () => {
  return useContext(FirestoreContext);
};

export default function FirestoreContextProvider({ children }) {
  return <FirestoreContext.Provider value={useFirestore()}>{children}</FirestoreContext.Provider>;
}
