import firebase from './firebase';

export const subscribeToNotice = ({ onSuccess, onFail }) => {
  // firebase.functions().useEmulator('localhost', 5001);
  var callable = firebase.functions().httpsCallable('subscribeToNotice');
  callable()
    .then(result => {
      console.log('Subscribe to notification service successfully.');
      if (onSuccess) onSuccess();
    })
    .catch(err => {
      console.warn('Failed to subscribe to notification service.', err.message);
      if (onFail) onFail();
    });
};
