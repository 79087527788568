import React, { useRef } from 'react';
import { Container, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube';
import { use100vh } from 'react-div-100vh';
import { useOrientation } from 'react-use';

import { useFirestoreContext } from '../FirestoreContext';
import RundownPage from './RundownPage';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function WorshipPage() {
  const { worship } = useFirestoreContext();
  const classes = useStyles();
  const location = useLocation();
  const realheight = use100vh();

  const playerRef = useRef(null);
  var { type: orientation } = useOrientation();
  const isHorizontal = orientation === 'landscape-primary' || orientation === 'landscape-secondary';
  const isShowPlayer = location.pathname === '/worship';
  const isShowRundown = location.pathname === '/rundown' || !isHorizontal;
  const playerWidth = isHorizontal ? 'calc((' + realheight + 'px - 6.5rem) / 0.5625)' : '100vw';
  const playerHeight = isHorizontal ? 'calc(' + realheight + 'px - 6.5rem)' : 'calc(100vw * 0.5625)';
  const rundownTop = isShowPlayer ? 'calc(' + playerHeight + ' + 3rem)' : '3rem';

  return (
    <Container className={classes.root}>
      {isShowPlayer && (
        <ReactPlayer
          url={worship?.youtubeUrl}
          width={playerWidth}
          height={playerHeight}
          controls={true}
          ref={playerRef}
        />
      )}
      {isShowRundown && (
        <Box width="100%" position="absolute" overflow="auto" top={rundownTop} bottom="3.5rem" left={0}>
          <Divider variant="middle" className={classes.divider} />
          <RundownPage />
        </Box>
      )}
    </Container>
  );
}
