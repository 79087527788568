import React from 'react';
import { ToBoolean } from '../utils/utils';
import { useHistory } from 'react-router-dom';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import NotificationsActiveRounded from '@material-ui/icons/NotificationsActiveRounded';
import Truncate from 'react-truncate';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';

import useShowDialogMessage from '../hooks/useShowDialogMessage';

const useStyles = makeStyles(theme => ({
  notificationButtonBase: {
    minWidth: '100%',
  },
  notificationContextBox: {
    width: '236px',
    marginRight: theme.spacing(0.5),
    flexGrow: 1,
  },
}));

const NotificationContent = ({ notification, customData, closeToast, toastProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const openDialogMessage = useShowDialogMessage();

  const jumpToNotice = ToBoolean(customData?.jumpToNotice);
  const showDialog = ToBoolean(customData?.showDialog);

  const onNotificationClickHandler = () => {
    closeToast();
    if (jumpToNotice) {
      history.push('/notice');
    }
    if (showDialog && customData?.noticeId) {
      openDialogMessage(customData?.noticeId);
    }
  };

  return (
    <ButtonBase disableRipple={true} className={classes.notificationButtonBase} onClick={onNotificationClickHandler}>
      <Box display="flex" flexDirection="row" flexGrow="1" width="100%">
        <NotificationsActiveRounded style={{ margin: '5px', marginRight: '10px', color: 'white' }} />
        <Box className={classes.notificationContextBox}>
          <Typography variant="h6" component="h2" align="left" gutterBottom>
            <Truncate lines={1}>{notification.title}</Truncate>
          </Typography>
          <Typography variant="body2" component="h2" align="left" gutterBottom>
            <Truncate lines={1}>{notification.body}</Truncate>
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
};

export const InAppPushNotificationHandler = payload => {
  const needToShowInAppNotification = payload?.data?.messageType === 'push-received';
  if (needToShowInAppNotification) {
    showNotification(payload.data.notification, payload.data.data);
  }
};

export const inAppNotfication = ({
  title,
  body,
  noticeId = '',
  autoClose = false,
  jumpToNotice = false,
  showDialog = true,
}) => {
  showNotification(
    {
      title: title,
      body: [body],
    },
    {
      noticeId: noticeId,
      autoClose: autoClose,
      jumpToNotice: jumpToNotice,
      showDialog: showDialog,
    }
  );
};

const showNotification = (notification, customData = undefined) => {
  const autoClose = ToBoolean(customData?.autoClose, true);
  toast.warn(<NotificationContent notification={notification} customData={customData} />, {
    position: 'top-right',
    autoClose: autoClose ?? 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: '#ffc400',
      color: '#424242',
      top: '42px',
      borderRadius: '10px',
    },
  });
};
