import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import KeepAlive from 'react-activation';

import usePDFDoc from '../components/pdf/PDFDoc';
import PDFViewer from '../components/pdf/PDFViewer';

import { useFirestoreContext } from '../FirestoreContext';

export default function WedPrayPage() {
  const { worship } = useFirestoreContext();
  const pdf = worship.wedPrayUrl;
  const { isValid, fingerprint } = usePDFDoc({ file: pdf });
  const isValidPdf = isValid && fingerprint;
  return (
    <>
      {isValidPdf ? (
        <KeepAlive name={fingerprint}>
          <PDFViewer file={pdf} />
        </KeepAlive>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
