import { useEffect, useState, useRef } from 'react';
import firebase from './firebase';

export default function useFirestore() {
  const [notices, setNoticeCollection] = useState([]);
  const [worship, setWorship] = useState();
  const noticeRef = useRef(notices);

  useEffect(() => {
    const db = firebase.firestore();
    // notice
    db.collection('notice')
      .where('isHidden', '==', false)
      .orderBy('isImportant', 'desc')
      .orderBy('createTimestamp', 'desc')
      .onSnapshot(querySnapshot => {
        const updatedNotices = [...noticeRef.current];
        querySnapshot.forEach(doc => {
          const docWithId = doc.data();
          docWithId.id = doc.id;
          const index = updatedNotices.findIndex((value, index, array) => value.id === doc.id);
          if (index >= 0) {
            updatedNotices[index] = docWithId;
          } else {
            updatedNotices.push(docWithId);
          }
        });
        setNoticeCollection(updatedNotices);
      });
    // worhsip
    db.collection('worship')
      .doc('links')
      .onSnapshot(doc => {
        setWorship(doc.data());
      });
  }, []);

  return { notices, worship };
}
