import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import KeepAlive from 'react-activation';

import { useFirestoreContext } from '../FirestoreContext';
import usePDFDoc from '../components/pdf/PDFDoc';
import PDFViewer from '../components/pdf/PDFViewer';

export default function RundownPage() {
  const { worship } = useFirestoreContext();
  const pdf = worship?.rundownUrl ? worship.rundownUrl : './rundown.pdf';
  const { isValid, fingerprint } = usePDFDoc({ file: pdf });

  return (
    <>
      {isValid && fingerprint && (
        <KeepAlive name={fingerprint}>
          <PDFViewer file={pdf} />
        </KeepAlive>
      )}
      {!(isValid && fingerprint) && (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
