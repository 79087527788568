import React, { useState, useRef } from 'react';
import { Box, Button, ButtonBase, ButtonGroup, Divider, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PDFPage from './PDFPage';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function PDFViewer({ file = './rundown.pdf' }) {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(1);
  // actual display scale
  const [scale, setScale] = useState(1);
  const [showZoomPanel, setShowZoomPanel] = useState(false);

  const containerRef = useRef(null);

  const handleSliderChange = (event, newValue) => {
    setScale(newValue);
  };

  const toggleZoomPanel = () => {
    console.log('toggle zoom panel, show=' + !showZoomPanel);
    setShowZoomPanel(!showZoomPanel);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      m={0}
      justifyContent="space-between"
      alignItems="space-between"
      width="100%"
      ref={containerRef}
    >
      {[...Array(numPages).keys()].map((value, index) => {
        return (
          <div key={file + '-page-' + (value + 1)}>
            <ButtonBase disableRipple={true} onClick={toggleZoomPanel} style={{ justifyContent: 'initial' }}>
              <PDFPage
                file={file}
                page={value + 1}
                scale={scale}
                minPdfScale={2}
                containerRef={containerRef}
                onPageLoadSuccess={({ hasNextPage, numPages }) => {
                  if (hasNextPage) {
                    setNumPages(value + 2);
                  } else {
                    console.log('all pages ' + (value + 1) + ' loaded');
                  }
                  // setNumPages(numPages);
                }}
              />
            </ButtonBase>
            <Divider variant="middle" className={classes.divider} />
          </div>
        );
      })}

      {/* Zoom panel */}
      <Box position="fixed" height="auto" bottom="4rem" left={'calc(50% - 85px)'} hidden={!showZoomPanel}>
        <Slider
          value={scale}
          onChange={handleSliderChange}
          aria-labelledby="Zoom"
          valueLabelDisplay="auto"
          min={1}
          max={5}
          step={0.01}
        />
        <ButtonGroup variant="contained" color="primary" aria-label="Zoom">
          <Button onClick={() => setScale(Math.max(1, scale - 1))}>-</Button>
          <Button>Zoom {Math.round(scale)}</Button>
          <Button onClick={() => setScale(Math.min(5, scale + 1))}>+</Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
}
