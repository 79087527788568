import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import ErrorIcon from '@material-ui/icons/Error';
import { useFirestoreContext } from '../FirestoreContext';

import routeData from '../routeData';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(0.5),
  },
  dialog: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1',
      width: 'auto',
      maxWidth: 'initial',
      margin: theme.spacing(0.5),
    },
    [theme.breakpoints.up('xs')]: {
      maxWidth: theme.breakpoints.values['md'],
    },
  },
}));

const formatDate = timestamp => format(timestamp, 'yyyy/MM/dd  aa hh:mm');

const isExternalUrl = url => {
  try {
    const checkUrl = new URL(url);
    return window.location.origin !== checkUrl.origin;
  } catch (err) {
    return false;
  }
};

export default function MessageDialog() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { notices } = useFirestoreContext();
  const descriptionElementRef = useRef(null);
  const dialogMessageId = location.state?.dialogMessage;
  const hasDialogMessage = dialogMessageId !== undefined;
  const dialogMessage = notices.find(value => value.id === dialogMessageId);

  useEffect(() => {
    if (hasDialogMessage) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [hasDialogMessage]);

  const RefButton = ({ title, url }) => {
    if (!url) return null;
    if (isExternalUrl(url)) {
      return (
        <Button variant="outlined" color="primary" target="_blank" rel="noopener" href={url} flex={1}>
          {title ?? '外部連結'}
        </Button>
      );
    }
    if (!!routeData.find(_ => _.path === url)) {
      return (
        <Button
          variant="outlined"
          color="primary"
          flex={1}
          onClick={() => {
            history.replace(url);
          }}
        >
          {title ?? '轉到頁面'}
        </Button>
      );
    }
    return null;
  };

  const onClose = () => history.goBack();

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={hasDialogMessage}
        onClose={onClose}
        scroll="paper"
        maxWidth={false}
        classes={{ paperFullWidth: classes.dialog }}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography variant="h6" display="inline" color="textPrimary" align="left">
              {dialogMessage?.title}
              {dialogMessage?.isImportant && <ErrorIcon color="error" className={classes.icon} />}
            </Typography>
            <Typography variant="subtitle2" display="inline" color="textSecondary" align="right">
              {dialogMessage?.createTimestamp && formatDate(dialogMessage?.createTimestamp?.toDate())}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers className={classes.dialog} ref={descriptionElementRef}>
          {dialogMessage?.content &&
            dialogMessage?.content.map((value, index) => {
              return (
                <Typography key={'message-id-' + index} variant="body2" color="textSecondary" component="div">
                  {value}
                </Typography>
              );
            })}
        </DialogContent>
        <DialogActions>
          <RefButton title={dialogMessage?.refTitle} url={dialogMessage?.refUrl} />
          <Button variant="contained" onClick={onClose} color="primary">
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
