import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { deepPurple, amber } from '@material-ui/core/colors';

const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: deepPurple[900],
    },
    secondary: amber,
  },
});

export default function ThemeContextProvider({ children }) {
  return <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>;
}
