import { useState } from 'react';
import { usePdf } from '@mikecousins/react-pdf';

export default function usePDFDoc({ file }) {
  const [fingerprint, setFingerprint] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [isValid, setIsValid] = useState(true);

  const handleOnDocumentLoadSuccess = ({ _pdfInfo }) => {
    if (_pdfInfo.fingerprint && fingerprint !== _pdfInfo.fingerprint) setFingerprint(_pdfInfo.fingerprint);
    if (_pdfInfo.numPages && numPages !== _pdfInfo.numPages) setNumPages(_pdfInfo.numPages);
  };

  const handleOnDocumentLoadFail = () => {
    setIsValid(false);
  };

  usePdf({
    file: file,
    page: 0,
    canvasRef: null,
    scale: 1,
    onDocumentLoadSuccess: handleOnDocumentLoadSuccess,
    onDocumentLoadFail: handleOnDocumentLoadFail,
  });

  return { isValid, fingerprint, numPages };
}
