import React, { useCallback } from 'react';
import { Box, Card, CardContent, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  card: {
    maxWidth: '500px',
    width: '100%',
    margin: theme.spacing(1),
  },
  iconButton: {
    margin: theme.spacing(0.1),
    padding: theme.spacing(0.5),
    paddingBottom: theme.spacing(1.2),
  },
}));

export default function AboutUsPage() {
  const classes = useStyles();

  const IconButtonContainer = useCallback(
    ({ href, children }) => {
      return (
        <IconButton color="secondary" target="_blank" rel="noreferrer" className={classes.iconButton} href={href}>
          {children}
        </IconButton>
      );
    },
    [classes.iconButton]
  );

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
      margin="20px"
    >
      <img src="../banner.png" alt="banner" className={classes.banner} />
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        className={classes.root}
      >
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5">教會地址</Typography>
            <Typography variant="body1">
              教會地址：九龍牛頭角道3號得寶花園地下
              <IconButtonContainer href="https://goo.gl/maps/mkQ6iXQMq4CdVjub6">
                <RoomRoundedIcon />
              </IconButtonContainer>
            </Typography>
            <Typography variant="body1">
              電話：27560136
              <IconButtonContainer href="tel:+852電話：27560136">
                <PhoneRoundedIcon />
              </IconButtonContainer>
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5">教牧同工辦公室地址</Typography>
            <Typography variant="body1">
              九龍灣德福大廈301-302A室
              <IconButtonContainer href="https://goo.gl/maps/xxXmyY2RbJJNJvqm9">
                <RoomRoundedIcon />
              </IconButtonContainer>
            </Typography>
            <Typography variant="body1">
              電話：27560136
              <IconButtonContainer href="tel:+85227560136">
                <PhoneRoundedIcon />
              </IconButtonContainer>
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="body1">
              教會網站：https://www.ntkbc.com.hk
              <IconButtonContainer href="https://www.ntkbc.com.hk/">
                <RoomRoundedIcon />
              </IconButtonContainer>
            </Typography>
            <Typography variant="body1">
              電郵：info@ntkbc.org.hk
              <IconButtonContainer href="mailto:info@ntkbc.org.hk">
                <EmailRoundedIcon />
              </IconButtonContainer>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
