import React, { useCallback } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format, isSameDay } from 'date-fns';
import Truncate from 'react-truncate';
import ErrorIcon from '@material-ui/icons/Error';

import useShowDialogMessage from '../hooks/useShowDialogMessage';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    // backgroundColor: 'lightgrey',
  },
  subTitle: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
  },
  space: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  message: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
  },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
}));

const FormatDate = timestamp => {
  return isSameDay(timestamp, Date.now()) ? format(timestamp, 'aa hh:mm') : format(timestamp, 'yyyy/MM/dd');
};

export default function MessageList({ messages, ...props }) {
  const classes = useStyles();

  const normalMessageIndex = messages.findIndex(value => !value.isImportant);
  const importantMessages = messages.slice(0, normalMessageIndex);
  const normalMessages = messages.slice(normalMessageIndex);

  const GroupTitle = useCallback(
    ({ title }) => {
      return (
        <>
          <ListItem divider className={classes.subTitle}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography variant="h6" align="left">
                    {title}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </>
      );
    },
    [classes.subTitle]
  );

  const Space = useCallback(() => {
    return <ListItem className={classes.space}></ListItem>;
  }, [classes.space]);

  const openDialogMessage = useShowDialogMessage();

  const Message = useCallback(
    ({ message }) => {
      return (
        <ListItem button divider className={classes.message} onClick={() => openDialogMessage(message.id)}>
          <ListItemText
            primary={
              <React.Fragment>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  // style={{ backgroundColor: 'lightGreen' }}
                >
                  <Typography variant="h6" display="inline" color="textPrimary" align="left">
                    {message.title}
                    {message.isImportant && <ErrorIcon color="error" className={classes.icon} />}
                  </Typography>
                  <Typography variant="subtitle2" display="inline" color="textSecondary" align="right">
                    {FormatDate(message.createTimestamp.toDate())}
                  </Typography>
                </Box>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Truncate lines={1}>{message.content[0]}</Truncate>
                {message.content.length > 1 ? <div>...</div> : <Space />}
              </React.Fragment>
            }
            secondaryTypographyProps={{ component: 'div' }}
          />
        </ListItem>
      );
    },
    [classes.message, classes.icon, openDialogMessage]
  );

  const MessagesGroup = useCallback(({ title, messages, endWithSpace = true }) => {
    if (messages.length <= 0) {
      return <></>;
    } else {
      return (
        <>
          <GroupTitle title={title} />
          {messages.map((value, _) => {
            console.log(value);
            return <Message key={'message-id-' + value.id} message={value} />;
          })}
          {endWithSpace && <Space />}
        </>
      );
    }
  }, []);

  return (
    <>
      <List className={classes.root} {...props}>
        <MessagesGroup title="重要通知" messages={importantMessages} />
        <MessagesGroup title="一般通知" messages={normalMessages} endWithSpace={false} />
      </List>
    </>
  );
}
