import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AliveScope } from 'react-activation';

import ThemeContextProvider from './ThemeContext';
import SettingContextProvider from './SettingContext';
import FirestoreContextProvider from './FirestoreContext';

import PageContainer from './components/PageContainer';
import routeData from './routeData';

function App() {
  return (
    <Router>
      <FirestoreContextProvider>
        <SettingContextProvider>
          <ThemeContextProvider>
            <AliveScope>
              <PageContainer>
                <Switch>
                  {routeData.map(({ path, component: Component }) => {
                    return <Route exact path={path} component={Component} key={Component.name} />;
                  })}
                  <Route path="/">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </PageContainer>
            </AliveScope>
          </ThemeContextProvider>
        </SettingContextProvider>
      </FirestoreContextProvider>
    </Router>
  );
}

export default App;
