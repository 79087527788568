import React from 'react';
import { cyan, deepPurple, pink, lightGreen, teal, orange } from '@material-ui/core/colors';

import HomeRounded from '@material-ui/icons/HomeRounded';
import NotificationsActiveRounded from '@material-ui/icons/NotificationsActiveRounded';
import LiveTvRoundedIcon from '@material-ui/icons/LiveTvRounded';
import ImportContactsRounded from '@material-ui/icons/ImportContactsRounded';
import FlareRoundedIcon from '@material-ui/icons/FlareRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';

import MainPage from './pages/MainPage';
import NoticePage from './pages/NoticePage';
import WorshipPage from './pages/WorshipPage';
import WedPrayPage from './pages/WedPrayPage';
import AboutUsPage from './pages/AboutUsPage';
import MorePage from './pages/MorePage';

const routeData = [
  {
    path: '/',
    title: '主頁',
    isInButtomTab: true,
    color: deepPurple[500],
    icon: (fontSize = 'default') => <HomeRounded fontSize={fontSize} />,
    homeScreenIdx: null,
    isShowInMore: false,
    component: MainPage,
  },
  {
    path: '/notice',
    title: '通知',
    isInButtomTab: true,
    color: cyan[500],
    icon: (fontSize = 'default') => <NotificationsActiveRounded fontSize={fontSize} />,
    homeScreenIdx: 1,
    isShowInMore: false,
    component: NoticePage,
  },
  {
    path: '/worship',
    title: '崇拜直播',
    isInButtomTab: true,
    color: orange[500],
    icon: (fontSize = 'default') => <LiveTvRoundedIcon fontSize={fontSize} />,
    homeScreenIdx: 2,
    isShowInMore: false,
    component: WorshipPage,
  },
  {
    path: '/rundown',
    title: '程序表',
    isInButtomTab: true,
    color: teal[500],
    icon: (fontSize = 'default') => <ImportContactsRounded fontSize={fontSize} />,
    homeScreenIdx: null,
    isShowInMore: false,
    component: WorshipPage,
  },
  {
    path: '/wedpray',
    title: '週三祈禱會',
    isInButtomTab: false,
    color: pink[500],
    icon: (fontSize = 'default') => <FlareRoundedIcon fontSize={fontSize} />,
    homeScreenIdx: 3,
    isShowInMore: true,
    component: WedPrayPage,
  },
  {
    path: '/aboutus',
    title: '關於我們',
    isInButtomTab: false,
    color: lightGreen[500],
    icon: (fontSize = 'default') => <InfoRoundedIcon fontSize={fontSize} />,
    homeScreenIdx: 4,
    isShowInMore: true,
    component: AboutUsPage,
  },
  {
    path: '/more',
    title: '更多',
    isInButtomTab: true,
    color: lightGreen[500],
    icon: (fontSize = 'default') => <MoreHorizRoundedIcon fontSize={fontSize} />,
    homeScreenIdx: null,
    isShowInMore: false,
    component: MorePage,
  },
];

export default routeData;
