import React, { useContext, useState } from 'react';
import { ToBoolean } from './utils/utils';

export const SettingContext = React.createContext();

export const useSettingContext = () => {
  return useContext(SettingContext);
};

const SETTING_SHOW_NOTIFICATION = 'setting_show_notification';
const SETTING_FCM_TOKEN_ID = 'setting_fcmToken';
export default function SettingContextProvider({ children }) {
  const [showNotification, _setShowNotification] = useState(
    ToBoolean(localStorage.getItem(SETTING_SHOW_NOTIFICATION) || true)
  );
  const [fcmToken, _setFcmToken] = useState(localStorage.getItem(SETTING_FCM_TOKEN_ID));
  const [installPromptEvent, setInstallPromptEvent] = useState();

  const setShowNotification = value => {
    _setShowNotification(value);
    localStorage.setItem(SETTING_SHOW_NOTIFICATION, value);
    if (!value) setFcmToken();
  };

  const setFcmToken = value => {
    _setFcmToken(value);
    if (value === undefined || value === null) {
      localStorage.removeItem(SETTING_FCM_TOKEN_ID);
    } else {
      localStorage.setItem(SETTING_FCM_TOKEN_ID, value);
    }
  };

  const value = { showNotification, setShowNotification, fcmToken, setFcmToken, installPromptEvent };

  window.addEventListener('beforeinstallprompt', event => {
    // Prevent Chrome <= 67 from automatically showing the prompt
    event.preventDefault();
    // Stash the event so it can be triggered later.
    setInstallPromptEvent(event);
  });
  return <SettingContext.Provider value={value}>{children}</SettingContext.Provider>;
}
