import React from 'react';
import { Avatar, Box, Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import routeData from '../routeData';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  banner: {
    width: '100%',
    maxWidth: '362px',
    margin: '20px auto',
  },
  card: {
    display: 'flex',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(1),
  },
  gridContainer: {
    // override grid spacing
    marginBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    },
  },
}));

const HeroCard = ({ label, color, path, icon }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid item xs={6} sm={6} md={3}>
      <Card>
        <CardActionArea className={classes.card} onClick={() => history.push(path)}>
          <CardContent className={classes.cardContent}>
            <Avatar className={classes.avatar} style={{ background: color }}>
              {icon('large')}
            </Avatar>
            <Typography variant="body1" component="h5" align="center" style={{ color: color }}>
              {label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default function MainPage() {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <img src="../banner.png" alt="banner" className={classes.banner} />
      <Typography variant="h5" component="h2" align="center" gutterBottom>
        建立屬靈友伴，堅固作主門徒
      </Typography>
      <Typography variant="body2" component="h2" color="primary" gutterBottom>
        「我賜給你們一條新命令，乃是叫你們彼此相愛；我怎樣愛你們，你們也要怎樣相愛。你們若有彼此相愛的心，眾人因此就認出你們是我的門徒了。」
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {'（約 13：34-35）'}
        </Typography>
      </Typography>
      <Grid container alignContent="center" direction="row" spacing={1} className={classes.gridContainer}>
        <>
          {routeData
            .filter(_ => !!_.homeScreenIdx)
            .sort((a, b) => a.homeScreenIdx < b.homeScreenIdx)
            .map(({ title, path, color, icon }) => {
              return <HeroCard path={path} label={title} color={color} icon={icon} key={path} />;
            })}
        </>
      </Grid>
    </Box>
  );
}
